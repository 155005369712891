<template>
	<section class="add-exercise-container">
		<h1>Modifier package Question flash</h1>
		<form @submit.prevent="updatePackage()"  v-if="flashPackage">
			<div class="input-container">
				<label for="title">Titre</label>
				<input type="text" id="title" v-model="flashPackage.title">
			</div>
			<div class="question-container" v-for="question in questionsList" :key="question">
				<p class="title">{{question.title}}</p>
				<p class="content">{{question.description}}</p>
			</div>
			<button>Valider</button>
		</form>
	</section>
</template>
<script>
import { getQuestionFlashPackageService,updateQuestionFlashPackageService,validateQuestionFlashPackageService } from '../../../services/questionFlashPackagesServices'
import { getQuestionService } from '../../../services/questionsService'

export default {
	data() {
		return {
			flashPackage: {
				title: '',
				questions: [],
			},
			questionsList: [],
		}
	},

	methods: {
		async getPackage() {
			this.flashPackage = await getQuestionFlashPackageService(this.$route.params.id)

			for(const question of this.flashPackage.questions){
				const res = await getQuestionService(question)
				this.questionsList.push(res)
			}
		},
		async updatePackage() {
			await updateQuestionFlashPackageService(this.$route.params.id,this.flashPackage)

			

			if (this.flashPackage.status !== 'VALIDATED'){
				await validateQuestionFlashPackageService(this.$route.params.id)
			}
			this.$store.state.toast = {
				text: 'Package de question flash modifié avec succès',
				status: 'success'
			}
			this.$router.go(-1)
		},

		
	},
	


	created() {
		this.getPackage()
	}
}
</script>
<style scoped lang="scss">
form{
	margin-inline: auto;
	margin-block: 4rem;

	.question-container{
		margin-block: 2rem;
		.title{
			font-size: 1.5rem;
			font-weight: bold;
		}
		.content{
			font-size: 1.2rem;
		}
	}
}
</style>
